import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Helmet from "react-helmet"
import {Button, Card} from "@material-ui/core"
import {Link} from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: הדף לא נמצא" />
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <div style={{
      display: "flex",
      justifyContent: "center",
      width: '100%',
      height: '100%',
    }}>
      <Card style={{
        padding: 48,
        marginTop:  80,
        marginLeft: 8,
        marginRight: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
      }}>
        <h1 style={{margin: 0}}>זה יכול לקרות…</h1>
        <p>הדף לא קיים. מה עושים? פשוט חוזרים לדף הבית.</p>
        <Button
          color="primary"
          variant="outlined"
          component={Link}  
          to="/"
        >
          חזרה לדף הבית
        </Button>
      </Card>
    </div>
    
  </Layout>
)

export default NotFoundPage
